<!-- AdminLayout.vue -->
<template>
    <div id="dtr-wrapper" class="clearfix">
        <Header />
         <router-view></router-view>
        <Footer />
    </div>
  </template>
  
  <script>
  import Header from "../components/userLayout/Header.vue";
  import Footer from "../components/userLayout/Footer.vue";
  
  export default {
    name: "AdminLayout",
    components: { Header, Footer },
    mounted() {},
    //     methods: {
//     loadScripts() {
//       const scripts = [
//         "../../public/assets/jsLanding/jquery.min.js",
//         "../../public/assets/jsLanding/bootstrap.min.js",
//         "../../public/assets/jsLanding/plugins.js",
//         "../../public/assets/jsLanding/slick.min.js",
//         "../../public/assets/jsLanding/custom.js"
//       ];

//       scripts.forEach(src => {
//         const script = document.createElement('script');
//         script.src = src;
//         script.async = true;
//         document.body.appendChild(script);
//       });
//     }
//   },
//   beforeCreate(){
//     this.loadScripts()
//   }
  };
  </script>
  
<style scoped src="../../public/assets/cssLanding/bootstrap.min.css"></style>
<style scoped src="../../public/assets/cssLanding/bootstrap.min.css"></style>
<style scoped src="../../public/assets/fontsLanding/iconfonts.css"></style>
<style scoped src="../../public/assets/cssLanding/plugins.css"></style>
<style scoped src="../../public/assets/cssLanding/style.css"></style>
<style scoped src="../../public/assets/cssLanding/responsive.css"></style>
<style scoped src="../../public/assets/cssLanding/color.css"></style>
  
<template>
       <!-- footer section starts
    ================================================== -->
    <footer id="dtr-footer"> 
            
        <!--== footer main starts ==-->
        <div class="dtr-footer-main dtr-box-layout"> 
            
            <!--== row starts ==-->
            <div class="row"> 
                
                <!-- column 1 starts -->
                <div class="col-12 col-md-6 footer-col-1">
                    <a class="logo-alt dtr-scroll-link" href="index.html">
                        <img src="assets/imagesLanding/new-logo.png" alt="logo">                         
                    </a>
                    <!-- <p class="dtr-mt-20">&copy; Copyright 2021. Made by <a href="https://themeforest.net/user/tansh">Tansh</a></p> -->
                </div>
                <!-- column 1 ends --> 
                
                <!-- column 2 starts -->
                <div class="col-12 col-md-6 footer-col-2"> 
                    
                    <!-- list starts -->
                    <ul class="dtr-list-inline">
                        <li><a href="index.html" class="dtr-scroll-link">Home</a></li>
                        <li><a href="pricing.html" class="dtr-scroll-link">Pricing</a></li>
                    </ul>
                    <!-- list ends --> 
                    
                    <!-- social starts -->
                    <ul class="dtr-social dtr-social-list dtr-mt-20">
                        <li><a href="#" class="dtr-instagram" target="_blank" title="instagram"></a></li>
                        <li><a href="#" class="dtr-skype" target="_blank" title="skype"></a></li>
                        <li><a href="#" class="dtr-google" target="_blank" title="google"></a></li>
                        <li><a href="#" class="dtr-twitter" target="_blank" title="twitter"></a></li>
                    </ul>
                    <!-- social ends --> 
                    
                </div>
                <!-- column 2 ends --> 
                
            </div>
            <!--== row ends ==--> 
            
        </div>
        <!--== footer main ends ==--> 
        
    </footer>
    <!-- footer section ends
================================================== --> 
</template>

<script>
export default {
 name: "FooterPage",
 props: {
   msg: String,
 },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped src="../../../public/assets/cssLanding/bootstrap.min.css"></style>
<style scoped src="../../../public/assets/fontsLanding/iconfonts.css"></style>
<style scoped src="../../../public/assets/cssLanding/plugins.css"></style>
<style scoped src="../../../public/assets/cssLanding/style.css"></style>
<style scoped src="../../../public/assets/cssLanding/responsive.css"></style>
<style scoped src="../../../public/assets/cssLanding/color.css"></style>

<template>
        <!-- Small Devices Header
    ============================================= -->
    <div class="dtr-responsive-header fixed-top">
        <div class="container"> 
            
            <!-- small devices logo --> 
            <a href="index-1.html"><img src="assets/imagesLanding/logo.png" alt="logo"></a> 
            <!-- small devices logo ends --> 
            
            <!-- menu button -->
            <button id="dtr-menu-button" class="dtr-hamburger" type="button"><span class="dtr-hamburger-lines-wrapper"><span class="dtr-hamburger-lines"></span></span></button>
        </div>
        <div class="dtr-responsive-header-menu"></div>
    </div>
    <!-- Small Devices Header ends
============================================= --> 
    
    <!-- header starts
============================================= -->
    <header id="dtr-header-global" class="fixed-top">
        <div class="d-flex align-items-center justify-content-between"> 
            
            <!-- header left starts -->
            <div class="dtr-header-left"> 
                
                <!-- logo --> 
                <router-link class="logo-default dtr-scroll-link" to="/">
                    <img src="assets/imagesLanding/new-logo.png" alt="logo">
                    <!-- Strata Management -->
                </router-link> 
                
                <!-- logo on scroll --> 
                <router-link class="logo-alt dtr-scroll-link" to="/">
                    <img src="assets/imagesLanding/new-logo.png" alt="logo">
                    <!-- Strata Management -->
                </router-link> 
                <!-- logo on scroll ends --> 
                
            </div>
            <!-- header left ends --> 
            
            <!-- header right starts -->
            <div class="dtr-header-right ml-auto"> 
                
              
                
                <!-- menu starts-->
                <div class="main-navigation">
                    <ul class="sf-menu dtr-scrollspy dtr-nav dark-nav-on-load dark-nav-on-scroll">
                        <li> <router-link class="nav-link" to="/">Home</router-link></li>
                        <li> <router-link class="nav-link" to="/pricing">Pricing</router-link></li>
                        <li> <router-link class="nav-link" to="/login">Login</router-link></li>
                    </ul>
                </div>
                <!-- menu ends --> 
                
            </div>
            <!-- header right ends --> 
            
        </div>
    </header>
    <!-- header ends
================================================== --> 
</template>

<script>
export default {
 name: "HeaderPage",
 props: {
   msg: String,
 },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped src="../../../public/assets/cssLanding/bootstrap.min.css"></style>
<style scoped src="../../../public/assets/fontsLanding/iconfonts.css"></style>
<style scoped src="../../../public/assets/cssLanding/plugins.css"></style>
<style scoped src="../../../public/assets/cssLanding/style.css"></style>
<style scoped src="../../../public/assets/cssLanding/responsive.css"></style>
<style scoped src="../../../public/assets/cssLanding/color.css"></style>

export const apis = {
    // Member
    GET_MEMBER_BY_ADMIN_ID: 'member/',
    GET_MEMBER_BY_ID: 'member/find/',
    ADD_MEMBER: 'member/add',
    EDIT_MEMBER_BY_ID: 'member/update/',
    DELETE_MEMBER_BY_ID: 'member/delete/',

    //Notice
    GET_NOTICE_ADMIN_ID: 'notice/id/',
    GET_NOTICE_BY_ID: 'notice/find/',
    ADD_NOTICE: 'notice/create',
    EDIT_NOTICE_BY_ID: 'notice/update/',
    DELETE_NOTICE_BY_ID: 'notice/delete/',

    ADD_COMMENTS_BY_ID: 'notice/add-comment/',
    DELETE_COMMENTS_BY_ID: 'notice/delete-comment/',
    ADD_COMMENTS_REPLY_BY_ID: 'notice/add-replies/',
    DELETE_COMMENTS_REPLY_BY_ID: 'notice/delete-reply/',
    COMMENTS_LIKES_BY_ID: 'notice/like-comment/',
    COMMENTS_REPLY_LIKES_BY_ID: 'notice/like-reply/',

    //Unit
    GET_UNIT_ADMIN_ID: 'unit/',
    GET_UNIT_BY_ID: 'unit/find/',
    ADD_UNIT: 'unit/add',
    EDIT_UNIT_BY_ID: 'unit/update/',
    DELETE_UNIT_BY_ID: 'unit/delete/',

    //Venues
    GET_VUENUES_ADMIN_ID: 'venue/all/',
    GET_VUENUE_BY_ID: 'venue/',
    ADD_VENUE: 'venue/create',
    EDIT_VENUE_BY_ID: 'venue/update/',
    DELETE_VENUE_BY_ID: 'venue/delete/',

    //Polling
    GET_POLLS_ADMIN_ID: 'poll/all/',
    GET_POLL_BY_ID: 'poll/',
    ADD_POLL: 'poll/create',
    EDIT_POLL_BY_ID: 'poll/update/',
    DELETE_POLL_BY_ID: 'poll/delete/',
    POLL_VOTES_BY_ID: 'poll/66e444fc82696cf7999b8d23/vote/66e16522b6c2b5013c21414c',
};
function middlewarePipeline (context, middleware, index) {
    // console.log('middlewarePipeline-context-',context);
    // console.log('middlewarePipeline-middleware-',middleware);
    // console.log('middlewarePipeline-index-',index);
    const nextMiddleware = middleware[index]
    // console.log('nextMiddleware-index-',nextMiddleware);
    if(!nextMiddleware){
        return context.next 
    }

    return () => {
        const nextPipeline = middlewarePipeline(
            context, middleware, index + 1
        )

        nextMiddleware({ ...context, next: nextPipeline })

    }
}

export default middlewarePipeline
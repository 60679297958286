// store/auth.js

// import * as dashboard from './user';
import axios from "axios";
import { apis } from '../../apis/admin';
// import router from "../../../router/index";

const state = {
    // Additional state if needed
    getVenues: '',
    venueById: '',
}

const mutations = {
    SET_VENUES_DATA(state, data) {
        state.getVenues = data
    },
    SET_VENUE_BY_ID(state, data) {
        state.unitById = data
    },
}

const actions = {
    // Additional actions if needed
      getVenues({ commit }, id) {
            // return
            // return new Promise((resolve, reject) => {
                axios
                    .get(axios.defaults.baseURL + apis.GET_VUENUES_ADMIN_ID + id, {
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem("token"),
                            "ngrok-skip-browser-warning": "69420",
                        },
                    })
                    .then((res) => {
                        console.log('getVenue---res !!', res)
                        if(Array.isArray(res.data.data)){
                            commit('SET_VENUES_DATA', res.data.data)
                        }
                        else{
                           commit('SET_VENUES_DATA', res.data.message)
                        }
                    })
                    .catch((err) => {
                        console.log('getVenue err', err)
                        // dispatch("checkErrorAndSendToast", [
                        //     err?.response,
                        //     "error",
                        //   ]);
                    })
            // })
    },

    getVenueById({ commit }, id) {
        // return
        // return new Promise((resolve, reject) => {
            axios
                .get(axios.defaults.baseURL + apis.GET_VUENUE_BY_ID + id, {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                        "ngrok-skip-browser-warning": "69420",
                    },
                })
                .then((res) => {
                    console.log('getVenueById---res !!', res)
                    if(Array.isArray(res.data.data)){
                        commit('SET_VENUE_BY_ID', res.data.data)
                    }
                    else{
                       commit('SET_VENUE_BY_ID', res.data.message)
                    }
                })
                .catch((err) => {
                    console.log('getVenueById err', err)
                })
        // })
    },

    addVenue({dispatch}, payload) {
        console.log("payload--", payload);
        // return
        var formData = new FormData();
        formData.append("adminId", payload.adminId);
        formData.append("name", payload.name);
        formData.append("location", payload.location);
        formData.append("openingTime", payload.openingTime);
        formData.append("capacity", payload.capacity);
        formData.append("closingTime", payload.closingTime);
        formData.append("openingDays", payload.openingDays);
        
        return new Promise((resolve, reject) => {
            axios
                .post(axios.defaults.baseURL + apis.ADD_VENUE, formData, {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                        "ngrok-skip-browser-warning": "69420",
                    },
                })
                .then((res) => {
                    console.log("addVenue res", res);
                    resolve(res);
                    // dispatch("checkErrorAndSendToast", [
                    //     res.data.message,
                    //     "success",
                    // ]);
                    // router.push("/all-enquiries")
                    dispatch('getVenues', payload.adminId)
                })
                .catch((err) => {
                    console.log("addVenue err", err);
                    reject(err);
                    // dispatch("checkErrorAndSendToast", [
                    //     err.response,
                    //     "error",
                    // ]);
                });
        });
    },

    editVenue({dispatch}, payload) {
        console.log("payload--", payload);
        // return
        var formData = new FormData();
        formData.append("UnitNumber", payload.UnitNumber);
        formData.append("Address", payload.Address);
        formData.append("Suburb", payload.Suburb);
        formData.append("StateofTerritory", payload.StateofTerritory);
        formData.append("PostalCode", payload.PostalCode);
        formData.append("UnitEquivalentValue", payload.UnitEquivalentValue);
        formData.append("Notes", payload.Notes);
        
        return new Promise((resolve, reject) => {
            axios
                .put(axios.defaults.baseURL + apis.EDIT_UNIT_BY_ID + payload.id, formData, {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                        "ngrok-skip-browser-warning": "69420",
                    },
                })
                .then((res) => {
                    console.log("editUnit res", res);
                    resolve(res);
                    dispatch("checkErrorAndSendToast", [
                        res.data.message,
                        "success",
                    ]);
                    // router.push("/my-enquiries")
                    dispatch('getUnit', payload.adminId)
                })
                .catch((err) => {
                    console.log("editUnit err", err);
                    reject(err);
                    // dispatch("checkErrorAndSendToast", [
                    //     err.response,
                    //     "error",
                    // ]);
                });
        });
    },


    deleteVenueById({dispatch }, payload) {
        // return
        return new Promise((resolve, reject) => {
            axios
                .delete(axios.defaults.baseURL + apis.DELETE_VENUE_BY_ID + payload.id, {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                        "ngrok-skip-browser-warning": "69420",
                    },
                })
                .then((res) => {
                    console.log('deleteVenueById---res !!', res)
                    resolve(res)
                    dispatch('getVenues', payload.adminId)
                })
                .catch((err) => {
                    reject(err)
                    console.log('deleteVenueById err', err)
                })
        })
    },

}

const getters = {
    // Additional getters if needed
}

export default {
    state,
    mutations,
    actions,
    getters,
  };
import { createStore } from 'vuex';
// import App from "../../src/app.vue";
//auth and toast
import auth from './auth.js';
// import VerifyErrorsAndToasts from './verifyErrorsAndToasts.js';

//admin
import member from './module/admin/member.js';
import announcement from './module/admin/announcement.js';
import unit from './module/admin/unit.js';
import venue from './module/admin/venue.js';
import polling from './module/admin/polling.js';


export default createStore({
  modules: {
    // auth and toast
    auth,

    //admin
    member,
    announcement,
    unit,
    venue,
    polling,
  },
});
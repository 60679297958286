<template>
  <component :is="currentLayout">
    <router-view/>
  </component>
</template>

<script>
import { mapState } from "vuex";
// import Toasts from "@/components/partials/toast.vue";

export default {
  name: 'App',
  components: {
    // Toasts
  },

  computed: {
    ...mapState({
      layout: (state) => state.auth.componentLayout,
    }),
    currentLayout() {
      console.log('this.layout', this.layout);
      return this.layout;
    },
  },
}
</script>
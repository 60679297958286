import { createRouter, createWebHistory } from 'vue-router'
import store from "../store";
import auth from '../middleware/auth'
import middlewarePipeline from './middlewarePipeline'

import AdminLayout from '@/layout/AdminLayout.vue'
import UserLayout from '@/layout/UserLayout.vue';

const routes = [
  {
    path: '/',
    name: 'LandingPage',
    component: UserLayout,
    children: [
      {
        path: "",
        name: "LandingPage",
        component: () => import("../views/Landing/Index.vue"),
        meta: { authRequired: false, title: 'Home Page | Strata Management' },
      },
      {
        path: "/pricing",
        name: "PricingPage",
        component: () => import("../views/Landing/Pricing.vue"),
        meta: { authRequired: false, title: 'Pricing | Strata Management' },
      },
    ]
  },
  {
    path: '/admin',
    name: 'HomePage',
    component: AdminLayout,
    meta: {
      middleware: [
          auth
      ],
      portal: 'Admin'
    },
    children: [
      {
        path: "",
        name: "DashboardPage",
        component: () => import("../views/admin/Dashboard.vue"),
        meta: { authRequired: false, title: 'Home | Strata Management' },
      },
      {
        path: "/units",
        name: "UnitsPage",
        component: () => import("../views/admin/Units.vue"),
        meta: { authRequired: false, title: 'Unit | Strata Management' },
      },
      {
        path: "/member",
        name: "MemberPage",
        component: () => import("../views/admin/Member.vue"),
        meta: { authRequired: false, title: 'Member | Strata Management' },
      },
      {
        path: "/announcements",
        name: "AnnouncementsPage",
        component: () => import("../views/admin/notice/Announcements.vue"),
        meta: { authRequired: false, title: 'Announcements | Strata Management' },
      },
      {
        path: "/announcement-details=:id",
        name: "AnnouncementDetailsPage",
        component: () => import("../views/admin/notice/Announcement_Details.vue"),
        meta: { authRequired: false, title: 'Announcement Details | Strata Management' },
      },
      {
        path: "/messaging",
        name: "MessagingPage",
        component: () => import("../views/admin/Messaging.vue"),
        meta: { authRequired: false, title: 'Messaging | Strata Management' },
      },
      {
        path: "/meeting",
        name: "MeetingPage",
        component: () => import("../views/admin/meeting/Meetings.vue"),
        meta: { authRequired: false, title: 'Meetings | Strata Management' },
      },
      {
        path: "/meeting-details",
        name: "MeetingDetailsPage",
        component: () => import("../views/admin/meeting/Meeting_Details.vue"),
        meta: { authRequired: false, title: 'Meeting Details | Strata Management' },
      },
      {
        path: "/polling",
        name: "PollingPage",
        component: () => import("../views/admin/Polling.vue"),
        meta: { authRequired: false, title: 'Polling | Strata Management' },
      },
      {
        path: "/finance",
        name: "FinancePage",
        component: () => import("../views/admin/finance/Finance.vue"),
        meta: { authRequired: false, title: 'Finance | Strata Management' },
      },
      {
        path: "/venues",
        name: "VenuesPage",
        component: () => import("../views/admin/venues/Venues.vue"),
        meta: { authRequired: false, title: 'Venues | Strata Management' },
      },
      {
        path: "/pinboard",
        name: "PinboardPage",
        component: () => import("../views/admin/Pinboard.vue"),
        meta: { authRequired: false, title: 'Pinboard | Strata Management' },
      },
      {
        path: "/document",
        name: "DocumentPage",
        component: () => import("../views/admin/document/Documents.vue"),
        meta: { authRequired: false, title: 'Documents | Strata Management' },
      },
      {
        path: "/document-pinboard",
        name: "DocumentPinboardPage",
        component: () => import("../views/admin/document/Document_Pinboard.vue"),
        meta: { authRequired: false, title: 'Document Pinboard | Strata Management' },
      },
      {
        path: "/document-upload",
        name: "DocumentUploadPage",
        component: () => import("../views/admin/document/Document_Upload.vue"),
        meta: { authRequired: false, title: 'Document Upload | Strata Management' },
      },
      {
        path: "/link",
        name: "LinkPage",
        component: () => import("../views/admin/Links.vue"),
        meta: { authRequired: false, title: 'Link | Strata Management' },
      },
    ]
  },

  // Login page
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "about" */ '../views/Login.vue'),
    meta: { authRequired: false, title: 'Login | Strata Management' },
  },
  // Register page
  {
    path: '/register',
    name: 'RegisterPage',
    component: () => import(/* webpackChunkName: "about" */ '../views/Register.vue'),
    meta: { authRequired: false, title: 'Register | Strata Management' },
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  console.log("to", to);
  console.log("from", from);
  console.log("next", next());
  document.title = to.meta.title || 'Strata Management';

  window.scrollTo(0, 0);

  if (!to.meta.middleware) {
      return next()
  }
  const middleware = to.meta.middleware
  console.log('router * middleware', middleware);
  const context = {
      to,
      from,
      next,
      store
  }

   return middleware[0]({
      ...context,
      next: middlewarePipeline(context, middleware, 1)
  })
});

export default router

<!-- components/Loader.vue -->
<template>
    <div
      class="lds-ring"
      :style="{ width: loaderSize + 'px', height: loaderSize + 'px' }"
    >
      <div :style="{ width: loaderSize + 'px', height: loaderSize + 'px','border-color': color }"></div>
      <div :style="{ width: loaderSize + 'px', height: loaderSize + 'px' }"></div>
      <div :style="{ width: loaderSize + 'px', height: loaderSize + 'px'}"></div>
      <div :style="{ width: loaderSize + 'px', height: loaderSize + 'px' }"></div>
    </div>
  </template>
  
  <script>
  export default {
    name: "LoaderPage",
    props: {
      size: {
        type: [Number, String],
        default: 20, // Default size, change as needed
      },
      color: {
        type: String,
        default: '#3498db', // Default color, change as needed
      },
    },
    computed:{
      loaderSize(){
        // sizes 
        // s = 15
        // m = 28
        // l = 40
        return this.size == 's' ? 15 : (this.size == 'm') ? 28 : (this.size == 'l') ? 40 : (this.size)
      }
    }
  }
  </script>
  
  <style scoped>
  .loader {
    /* Your loader styles go here */
    color: '#3498db';
  }
  .lds-ring {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 28px;
    height: 28px;
    margin: 0px;
    border: 3px solid #fff;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #fff transparent transparent transparent;
  }
  .lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
  }
  .lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
  }
  .lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  </style>
  
<template>
    <!-- partial:partials/_footer.html -->
    <footer class="footer">
        <div class="d-sm-flex justify-content-center justify-content-sm-between">
          <span class="text-muted text-center text-sm-left d-block d-sm-inline-block">Strata Managament</span>
        </div>
    </footer>
</template>

<script>
export default {
 name: "FooterPage",
 props: {
   msg: String,
 },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
